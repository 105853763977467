import axios from 'axios';
import { URI, TEST_URI } from './const';

export const vote = async (id: string, abnormal: boolean) => {
  const result = await axios.post(URI + '/mutation/vote', {
    id,
    abnormal,
  });

  return result;
};
