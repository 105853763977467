interface Props {
  type: 'dot' | 'line';
  size: 'sm' | 'md' | 'lg';
}

const LoadingIndicator = ({ size, type }: Props) => {
  const _size = () => {
    switch (size) {
      case 'sm':
        return 'h-4 w-4';
      case 'md':
        return 'h-6 w-6';
      case 'lg':
        return 'h-8 w-8';
    }
  };

  return (
    <div
      className={`inline-block ${_size()} animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
      role="status"
    ></div>
  );
};

export default LoadingIndicator;
