import React from 'react';

const PageLayout: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { cssString?: string }
> = ({ children, cssString }) => {
  return (
    <div
      className={`flex h-full w-full justify-center pt-2 px-[20px] ${cssString}`}
    >
      <div className="flex w-full min-w-[300px] max-w-[800px] lg:min-w-[1000px] lg:max-w-7xl gap-3 flex-col">
        {children}
      </div>
    </div>
  );
};

export default PageLayout;
