import React, { useEffect, useState } from 'react';
import Text from 'components/text';
import QueryBlock, { QueryKey } from './queryBlock';
import Button from 'components/button';

const KEY_LIST: QueryKey[] = [
  {
    key: 'name',
    label: '이름',
    valueType: 'string',
    operatorList: ['=', '!='],
  },
  // {
  //   key: 'birth_date',
  //   label: '생년월일',
  //   valueType: 'date',
  //   defaultValue: moment().format('YYYY-MM-DD'),
  //   operatorList: ['=', '!=', '>', '<', '>=', '<='],
  // },
  {
    key: 'created_at',
    label: '생성일시',
    valueType: 'datetime',
    operatorList: ['=', '!=', '>', '<', '>=', '<='],
  },
  {
    key: 'data_type',
    label: '데이터 타입',
    valueType: 'string',
    operatorList: ['=', '!='],
    listItem: [
      {
        key: 'ecg',
        label: '심전도',
      },
    ],
    defaultValue: 'ecg',
  },
  {
    key: 'device',
    label: '측정장비',
    valueType: 'string',
    operatorList: ['=', '!='],
    listItem: [
      {
        key: 'android',
        label: '안드로이드',
      },
      {
        key: 'ios',
        label: '애플',
      },
    ],
    defaultValue: 'android',
  },
  {
    key: 'abnormal',
    label: '정상 여부',
    valueType: 'boolean',
    operatorList: ['=', '!='],
    listItem: [
      {
        key: '0',
        label: '정상',
      },
      {
        key: '1',
        label: '비정상',
      },
    ],
    defaultValue: 'android',
  },
];

export type QueryOperator = '=' | '!=' | '>' | '<' | '>=' | '<=';

export interface QueryFilter {
  key: string;
  operator: QueryOperator;
  value: string | number | boolean | Date;
}

interface QueryProps {
  queryFilters: QueryFilter[];
  setQueryFilters: (queryFilters: QueryFilter[]) => void;
  onClickSearch?: () => void;
}

const Query: React.FC<QueryProps> = ({
  queryFilters,
  setQueryFilters,
  onClickSearch,
}) => {
  const changeQueryFilter = (index: number, queryFilter: QueryFilter) => {
    let resetValue = false;

    if (queryFilters[index].key !== queryFilter.key) {
      const isKeyUsed = queryFilters.find((q) => q.key === queryFilter.key);

      if (isKeyUsed) {
        return;
      }

      resetValue = true;
    }

    const newQueryFilters = [...queryFilters];
    newQueryFilters[index] = queryFilter;

    if (resetValue) {
      newQueryFilters[index].value = '';

      const keyListItem = KEY_LIST.find((key) => key.key === queryFilter.key);

      if (keyListItem?.defaultValue) {
        newQueryFilters[index].value = keyListItem.defaultValue;
      }
    }

    setQueryFilters(newQueryFilters);
  };

  const onDeleteFilter = (index: number) => {
    const newQueryFilters = [...queryFilters];
    newQueryFilters.splice(index, 1);
    setQueryFilters(newQueryFilters);
  };

  const onClickAddFilter = () => {
    const notUsedKeyList = KEY_LIST.filter(
      (key) => !queryFilters.find((queryFilter) => queryFilter.key === key.key),
    );

    if (notUsedKeyList.length === 0) {
      return;
    }

    const newFilterKey = notUsedKeyList[0].key;
    const keyListItem = KEY_LIST.find((key) => key.key === newFilterKey);
    const newQueryFilter: QueryFilter = {
      key: newFilterKey,
      operator: '=',
      value: '',
    };

    if (keyListItem?.defaultValue) {
      newQueryFilter.value = keyListItem.defaultValue;
    }

    setQueryFilters([...queryFilters, newQueryFilter]);
  };

  return (
    <div className="w-full bg-slate-100 rounded-lg p-4 flex flex-col lg:w-[520px]">
      <Text type="h5" bold>
        Query
      </Text>
      <div className="mt-2 transition-all">
        {queryFilters.map((queryFilter, index) => (
          <div key={index}>
            <QueryBlock
              keyList={KEY_LIST}
              selectedKey={queryFilter.key}
              value={queryFilter.value}
              selectedOperator={queryFilter.operator}
              onSelectKey={(key) => {
                changeQueryFilter(index, { ...queryFilter, key });
              }}
              onChangeValue={(value) => {
                changeQueryFilter(index, { ...queryFilter, value });
              }}
              onChangeOperator={(operator) => {
                changeQueryFilter(index, { ...queryFilter, operator });
              }}
              onDelete={() => {
                onDeleteFilter(index);
              }}
            />
            <div className="h-2" />
          </div>
        ))}
      </div>
      <div className="flex w-full justify-end">
        <Button
          size="sm"
          type="outline"
          text="필터 추가"
          color="text-slate-600"
          onClick={onClickAddFilter}
        />
        <div className="w-2" />
        <Button size="sm" text="검색" onClick={onClickSearch} />
      </div>
    </div>
  );
};

export default Query;
