import React, { ReactElement } from 'react';
import LoadingIndicator from 'components/loadingIndicator';

interface ButtonProps {
  onClick?: () => any;
  size: 'sm' | 'md' | 'lg';
  type?: 'outline' | 'fill';
  fullWidth?: boolean;
  text: string;
  disabled?: boolean;
  color?: string;
  loading?: boolean;
  className?: string;
  bold?: boolean;
  noBackground?: boolean;
  icon?: ReactElement;
}

const Button = ({
  size,
  type,
  fullWidth,
  text,
  loading,
  disabled,
  color,
  onClick,
  bold,
  noBackground,
  className,
  icon,
}: ButtonProps) => {
  const _onClick = () => {
    if (disabled) return;
    if (loading) return;

    if (onClick) onClick();
  };

  const _size = () => {
    switch (size) {
      case 'sm':
        return 'px-2 py-1 text-xs rounded-sm sm:rounded sm:px-2 sm:py-1 sm:text-sm';
      case 'md':
        return 'px-2 py-1 text-sm rounded-sm sm:rounded-md sm:px-4 sm:py-2 sm:text-base';
      case 'lg':
        return 'px-4 py-2 text-base rounded-md sm:px-8 sm:py-4 sm:text-lg sm:rounded-lg';
    }
  };

  const _type = () => {
    switch (type) {
      case 'outline':
        return 'border border-slate-300 bg-transparent hover:bg-slate-200';
      case 'fill':
        return '';
    }
  };

  return (
    <div
      className={`${
        fullWidth ? 'w-full' : 'w-fit'
      } flex items-center justify-center select-none
      ${_type()}
      ${_size()} 
      ${bold ? 'font-bold' : 'font-normal'} 
      ${color ? color : 'text-white'} 
      ${
        disabled || loading
          ? 'opacity-50 cursor-not-allowed bg-slate-300'
          : 'bg-slate-400 cursor-pointer hover:bg-slate-300'
      } 
      ${noBackground && 'bg-transparent'}
      ${className}
      `}
      onClick={_onClick}
    >
      {icon}
      {loading ? <LoadingIndicator size="md" type="line" /> : text}
    </div>
  );
};

export default Button;
