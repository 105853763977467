import axios from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import Zoom from 'chartjs-plugin-zoom';
import Button from 'components/button';
import { blockchainQuery } from 'api/query';
import crypto from 'crypto-js';
import { vote } from 'api/mutation';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Zoom,
);

interface ECGExpandedProps {
  id: string;
  rawData: any;
  onReloadRequired: () => void;
}

const ECGExpanded: React.FC<ECGExpandedProps> = ({
  id,
  rawData,
  onReloadRequired,
}) => {
  const [ecgData, setEcgData] = useState<any[]>([]);
  const [rawEcgData, setRawEcgData] = useState<string>('');
  const [blockchainECGData, setBlockchainECGData] = useState<string>('');
  const [hashChecked, setHashChecked] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [blockchainError, setBlockchainError] = useState<string | null>(null);

  const ecgGraphData = useMemo(() => {
    return ecgData.map((item) => item[1]);
  }, [ecgData]);

  const labels = useMemo(() => {
    if (rawData.device === 'android') {
      const timestamps = ecgData.map((item) => item[0]);
      const start = Number(timestamps[0]);
      const labels = [];

      for (let i = 0; i < timestamps.length; i++) {
        labels.push((Number(timestamps[i]) - start) / 1000);
      }

      return labels;
    }

    return ecgData.map((item) => item[0]);
  }, [ecgData, rawData]);

  const ecgPeaks = useMemo(() => {
    const findLocalMaxima1d = (
      arr: number[],
    ): { midpoints: number[]; leftEdges: number[]; rightEdges: number[] } => {
      const midpoints: number[] = [];
      const leftEdges: number[] = [];
      const rightEdges: number[] = [];

      for (let i = 1; i < arr.length - 1; i++) {
        if (arr[i - 1] < arr[i]) {
          let iAhead = i + 1;

          while (iAhead < arr.length && arr[iAhead] === arr[i]) {
            iAhead++;
          }

          if (arr[iAhead] < arr[i]) {
            leftEdges.push(i);
            rightEdges.push(iAhead - 1);
            midpoints.push(Math.floor((i + iAhead - 1) / 2));

            i = iAhead;
          }
        }
      }

      return { midpoints, leftEdges, rightEdges };
    };

    const unpackConditionArgs = (
      interval: number | number[],
      x: number[],
      peaks: number[],
    ) => {
      let imin: any, imax: any;

      // Check if interval is an array and has two elements
      if (Array.isArray(interval) && interval.length === 2) {
        imin = interval[0];
        imax = interval[1];
      } else {
        imin = interval;
        imax = null;
      }

      // Reduce arrays if they are arrays
      if (Array.isArray(imin)) {
        if (imin.length !== x.length) {
          throw new Error('array size of lower interval border must match x');
        }
        imin = peaks.map((index) => imin[index]);
      }
      if (Array.isArray(imax)) {
        if (imax.length !== x.length) {
          throw new Error('array size of upper interval border must match x');
        }
        imax = peaks.map((index) => imax[index]);
      }

      return [imin, imax];
    };

    function selectByProperty(
      peakProperties: Array<number>,
      pmin: number | null,
      pmax: number | null,
    ): Array<boolean> {
      let keep: Array<boolean> = new Array(peakProperties.length).fill(true);

      if (pmin !== null) {
        keep = keep.map((val, idx) => val && pmin <= peakProperties[idx]);
      }

      if (pmax !== null) {
        keep = keep.map((val, idx) => val && peakProperties[idx] <= pmax);
      }

      return keep;
    }

    const selectByPeakDistance = (
      peaks: number[],
      priority: number[],
      distance: number,
    ): boolean[] => {
      const peaksSize = peaks.length;
      const distanceCeil = Math.ceil(distance);
      const keep: boolean[] = new Array(peaksSize).fill(true);

      const priorityToPosition: number[] = priority
        .map((p, index) => ({ p, index }))
        .sort((a, b) => b.p - a.p)
        .map((data) => data.index);

      for (let i = peaksSize - 1; i >= 0; i--) {
        const j = priorityToPosition[i];
        if (!keep[j]) {
          continue;
        }

        let k = j - 1;
        while (k >= 0 && peaks[j] - peaks[k] < distanceCeil) {
          keep[k] = false;
          k -= 1;
        }

        k = j + 1;
        while (k < peaksSize && peaks[k] - peaks[j] < distanceCeil) {
          keep[k] = false;
          k += 1;
        }
      }

      return keep;
    };

    if (ecgGraphData.length > 0) {
      const { midpoints } = findLocalMaxima1d(ecgGraphData);
      let peaksX = ecgGraphData.filter((_, index) => midpoints.includes(index));

      const [hmin, hmax] = unpackConditionArgs(
        rawData.device === 'android' ? 300 : 0.00003,
        ecgGraphData,
        midpoints,
      );
      const keep = selectByProperty(peaksX, hmin, hmax);

      const peakPointsByHeight = midpoints.filter((_, index) => keep[index]);
      peaksX = ecgGraphData.filter((_, index) =>
        peakPointsByHeight.includes(index),
      );

      const realPeaksIndex = selectByPeakDistance(
        peakPointsByHeight,
        peaksX,
        rawData.device === 'android' ? 20 : 40,
      );

      const peakPoints = peakPointsByHeight.filter(
        (_, index) => realPeaksIndex[index],
      );
      return peakPoints;
    } else {
      return [];
    }
  }, [ecgGraphData]);

  const peakDists = useMemo(() => {
    const parseDists = (peakDists: string[]) => {
      const results = {
        avgValue: 0,
        avg: 0,
        below: 0,
        above: 0,
      };

      if (peakDists.length === 0) {
        return results;
      }

      const sortedDists = peakDists.sort((a, b) => Number(a) - Number(b));
      const midIndex = Math.floor(sortedDists.length / 2);
      const med = Number(sortedDists[midIndex]);

      // const sum = peakDists.reduce((a, b) => Number(a) + Number(b), 0);
      const avgValue = med;
      const avgBelow = avgValue - 0.1;
      const avgAbove = avgValue + 0.1;

      let avg = 0;
      let below = 0;
      let above = 0;

      for (const dist of peakDists) {
        if (Number(dist) < avgBelow) {
          below += 1;
        } else if (Number(dist) > avgAbove) {
          above += 1;
        } else {
          avg += 1;
        }
      }

      results.avgValue = avgValue;
      results.avg = avg;
      results.below = below;
      results.above = above;

      return results;
    };

    if (ecgPeaks.length === 0) {
      return parseDists([]);
    }

    const peakTimes = ecgPeaks.map((index) => ecgData[index][0]);
    const peakIntervals = peakTimes
      .map((time, index) => {
        if (index === 0) {
          return '0';
        }

        let diff = Number(time) - Number(peakTimes[index - 1]);

        if (rawData.device === 'android') {
          diff = diff / 1000;
        }

        return diff.toFixed(2);
      })
      .splice(1);

    return parseDists(peakIntervals);
  }, [ecgPeaks]);

  const queryRequest = useRef<any>(null);
  const blockchainQueryRequest = useRef<any>(null);

  const getECGData = async () => {
    setError(null);

    const response = await axios.get(rawData.data, {});
    const { data, status } = response;

    let prev_value = null;

    if (status === 200) {
      setRawEcgData(data);

      const regex = /\([^)]*\)/gi;
      const match = (data || '').match(regex);

      const ecgData = [];

      for (const datapoint of match || []) {
        const [value, time] = datapoint
          .replace('(', '')
          .replace(')', '')
          .replace(' ', '')
          .split(',');

        if (prev_value === null) {
          prev_value = value;
        } else {
          const diff = Number(value) - Number(prev_value);
          ecgData.push([time, diff]);
          prev_value = value;
        }
      }

      setEcgData(ecgData);
    } else {
      setError('ECG 데이터를 불러오는데 실패했습니다.');
    }
  };

  const getBlockchainData = async () => {
    setBlockchainError(null);

    try {
      setLoading(true);
      const response = await blockchainQuery(id, 'ECG');
      const { data, status } = response;

      if (status === 200) {
        setBlockchainECGData(data.result.Data);
      }
    } catch (e) {
      setBlockchainError('블록체인 데이터를 불러오는데 실패했습니다.');
    }

    setLoading(false);
  };

  const onClickDownload = () => {
    const element = document.createElement('a');
    const file = new Blob([rawEcgData], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `${rawData.name}_${rawData.data_type}_${id}.txt`;
    document.body.appendChild(element);
    element.click();
  };

  const onClickAbnormalReport = async () => {
    try {
      const response = await vote(id, !rawData.abnormal);

      const { data, status } = response;

      let alertMessage = '';
      if (status === 200) {
        alertMessage = rawData.abnormal
          ? '정상 표기되었습니다.'
          : '비정상 표기되었습니다.';

        onReloadRequired();
      } else {
        alertMessage = rawData.abnormal
          ? '정상 표기에 실패했습니다.'
          : '비정상 표기에 실패했습니다.';
      }

      alert(alertMessage);
    } catch (e) {
      console.log(e);
    }
  };

  const hashCompare = () => {
    if (blockchainECGData && rawEcgData) {
      const hash1 = crypto.SHA256(blockchainECGData).toString();
      const hash2 = crypto.SHA256(rawEcgData).toString();

      if (hash1 === hash2) {
        setHashChecked(true);
      } else {
        setHashChecked(false);
      }
    }
  };

  useEffect(() => {
    queryRequest.current = getECGData();
    blockchainQueryRequest.current = getBlockchainData();
  }, []);

  useEffect(() => {
    if (blockchainECGData && rawEcgData) {
      hashCompare();
    }
  }, [blockchainECGData, rawEcgData]);

  return (
    <div className="flex flex-col w-full h-90 relative aspect-1">
      {rawData?.patient && (
        <div className="grid grid-cols-1 p-2 w-full">
          <div className="flex flex-col items-center justify-center">
            <div className="font-bold">진단명</div>
            <div>{rawData.patient.disease}</div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-3 p-2 w-full">
        <div className="flex flex-col items-center justify-center">
          <div className="font-bold">환자 이름</div>
          <div>{rawData.name}</div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="font-bold">데이터 타입</div>
          <div>{rawData.data_type}</div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="font-bold">측정 장비</div>
          <div>{rawData.device || 'N/A'}</div>
        </div>
      </div>

      <div className="grid grid-cols-3 p-2 w-full">
        <div className="flex flex-col items-center justify-center">
          <div className="font-bold">{`< ${(peakDists.avgValue - 0.1).toFixed(
            2,
          )}`}</div>
          <div>{peakDists.below}</div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="font-bold">
            중앙값 ({peakDists.avgValue.toFixed(2)}) ± 0.1
          </div>
          <div>{peakDists.avg}</div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="font-bold">{`> ${(peakDists.avgValue + 0.1).toFixed(
            2,
          )}`}</div>
          <div>{peakDists.above}</div>
        </div>
      </div>
      <div className="flex-1">
        {ecgData && ecgData.length > 0 && (
          <Line
            width={'100%'}
            data={{
              labels: labels,
              datasets: [
                {
                  label: 'ECG',
                  data: ecgGraphData || [],
                  fill: false,
                  pointRadius:
                    ecgGraphData.map((_, index) => {
                      if (ecgPeaks.includes(index)) {
                        return 2;
                      }
                      return 0;
                    }) || [],
                  borderColor: 'rgba(255, 99, 132, 1)',
                },
              ],
            }}
            options={{
              elements: {
                point: {
                  radius: 10,
                },
              },
              maintainAspectRatio: false,
              resizeDelay: 100,
              responsive: true,
              datasets: {
                line: {
                  borderWidth: 1,
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                zoom: {
                  pan: {
                    enabled: true,
                    mode: 'x',
                  },
                  zoom: {
                    wheel: {
                      enabled: true,
                    },
                    pinch: {
                      enabled: true,
                    },
                    drag: {
                      enabled: true,
                    },
                    mode: 'x',
                  },
                },
              },
              scales: {
                x: {
                  grid: {
                    display: true,
                  },
                  ticks: {
                    display: true,
                    minRotation: 90,
                    maxRotation: 90,
                    callback: function (val, index) {
                      return Number(labels[index]).toFixed(2);
                    },
                  },
                },
              },
            }}
          />
        )}
      </div>
      <div className="flex w-full justify-between pb-2 pt-1">
        <div className="flex items-center">
          <div className="mr-1">블록체인 상태: </div>
          {loading && <div className="mr-1">확인중...</div>}
          {!loading &&
            !blockchainError &&
            (hashChecked ? (
              <div className="text-green-500 font-bold">정상</div>
            ) : (
              <div className="text-red-500 font-bold">변조</div>
            ))}
          {!loading && blockchainError && (
            <div className="text-red-500 font-bold">{blockchainError}</div>
          )}
        </div>
        <div className="flex">
          <Button
            size="sm"
            type="outline"
            color="text-slate-700"
            text={rawData.abnormal ? '정상 표기' : '비정상 표기'}
            onClick={onClickAbnormalReport}
          />
          <div className="w-1" />
          <Button size="sm" text="다운로드" onClick={onClickDownload} />
        </div>
      </div>
    </div>
  );
};

export default ECGExpanded;

70;
