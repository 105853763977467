import axios from 'axios';
import { URI } from './const';
import { QueryFilter } from 'components/query';

export const query = async (
  queries: QueryFilter[],
  pagenation: {
    page: number;
    limit: number;
  },
) => {
  const result = await axios.post(URI + '/query', {
    query: queries,
    pagenation,
  });

  return result;
};

export const queryPatient = async () => {
  const result = await axios.get(URI + '/query/patient');

  return result;
};

export const blockchainQuery = async (id: string, type: 'ECG' | 'Image') => {
  const result = await axios.post(URI + '/query/blockchain', {
    id: id,
    type: type,
  });

  return result;
};
