import { Route, Routes } from 'react-router';

import Home from 'pages/home';
import List from 'pages/list';
import Header from 'components/header';

function App() {
  return (
    <div className="flex flex-col h-screen w-screen">
      <Header />
      <div className="relative flex-1 w-full">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/list" element={<List />} />
          <Route path="*" element={<h1>Not Found</h1>} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
