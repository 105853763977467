import axios from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
import crypto from 'crypto-js';
import { blockchainQuery } from 'api/query';

interface ImageExpandedProps {
  id: string;
  rawData: any;
  onReloadRequired: () => void;
}

const ImageExpanded: React.FC<ImageExpandedProps> = ({
  id,
  rawData,
  onReloadRequired,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [blockchainImageHash, setBlockchainImagHash] = useState<string>('');
  const [blockchainError, setBlockchainError] = useState<string | null>(null);
  const [imageHash, setImageHash] = useState<string | null>(null);
  const [hashChecked, setHashChecked] = useState<boolean>(false);

  const getBlockchainData = async () => {
    setBlockchainError(null);

    try {
      setLoading(true);
      const response = await blockchainQuery(id, 'Image');
      const { data, status } = response;

      if (status === 200) {
        setBlockchainImagHash(data.result.Data);
      }
    } catch (e) {
      setBlockchainError('블록체인 데이터를 불러오는데 실패했습니다.');
    }

    setLoading(false);
  };

  const getImage = async () => {
    const response = await axios.get(rawData.data, {
      responseType: 'blob',
    });
    const reader = new FileReader();
    reader.readAsDataURL(response.data);
    reader.onloadend = () => {
      const base64data = reader.result;
      const data = base64data?.toString().split(',')[1];
      const hash = crypto.SHA256(data || '').toString();
      setImageHash(hash);
    };
  };

  const hashCompare = () => {
    if (imageHash && blockchainImageHash) {
      if (imageHash === blockchainImageHash) {
        setHashChecked(true);
      } else {
        setHashChecked(false);
      }
    }
  };

  useEffect(() => {
    getBlockchainData();
  }, []);

  useEffect(() => {
    getImage();
  }, [rawData.data]);

  useEffect(() => {
    if (imageHash && blockchainImageHash) {
      hashCompare();
    }
  }, [blockchainImageHash, imageHash]);

  return (
    <div className="flex flex-col w-full h-82 relative aspect-1">
      <div className="grid grid-cols-3 p-2 w-full">
        <div className="flex flex-col items-center justify-center">
          <div className="font-bold">환자 이름</div>
          <div>{rawData.name}</div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="font-bold">데이터 타입</div>
          <div>{rawData.data_type}</div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className="font-bold">전송 장비</div>
          <div>{rawData.device || 'N/A'}</div>
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center">
        <img className="w-auto h-60" src={rawData.data || ''} alt="Image" />
      </div>
      {imageHash && (
        <div className="flex w-full justify-between pb-2 pt-1">
          <div className="flex items-center">
            <div className="mr-1">블록체인 상태: </div>
            {loading && <div className="mr-1">확인중...</div>}
            {!loading &&
              !blockchainError &&
              (hashChecked ? (
                <div className="text-green-500 font-bold">정상</div>
              ) : (
                <div className="text-red-500 font-bold">변조</div>
              ))}
            {!loading && blockchainError && (
              <div className="text-red-500 font-bold">{blockchainError}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageExpanded;
