interface CardProps {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}

const Card: React.FC<CardProps> = ({ children, onClick, disabled }) => {
  return (
    <div
      className={`max-w-full rounded overflow-hidden shadow-lg ${
        onClick ? 'hover:bg-slate-200 cursor-pointer' : ''
      } ${disabled ? ' cursor-not-allowed' : ''}`}
      onClick={onClick}
    >
      <div className="px-6 py-4">{children}</div>
    </div>
  );
};

export default Card;
