import React, { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

export interface Item {
  key: string;
  label: string;
}

interface SelectorProps {
  label?: string;
  items: Item[];
  selected: Item;
  onSelect: (item: Item) => void;
}

const Selector: React.FC<SelectorProps> = ({
  label,
  items,
  selected,
  onSelect,
}) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <Listbox value={selected} onChange={onSelect}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
            {label}
          </Listbox.Label>
          <div className={`relative ${label ? 'mt-2' : ''}`}>
            <Listbox.Button className="relative w-full h-[38px] cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              <span className="flex items-center">
                <span className="ml-0 block truncate">{selected.label}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {items.map((item) => (
                  <Listbox.Option
                    key={item.key}
                    className={` relative cursor-default select-none py-2 pl-3 pr-9 ${
                      item.key === selected.key
                        ? 'bg-indigo-600 text-white'
                        : 'text-gray-900'
                    }`}
                    value={item}
                  >
                    <div className="flex items-center">
                      <span
                        className={`ml-0 block truncate ${
                          item.key === selected.key
                            ? 'font-semibold'
                            : 'font-normal'
                        }`}
                      >
                        {item.label}
                      </span>
                    </div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default Selector;
