import Text from 'components/text';
import { useMemo } from 'react';

export interface InputProps {
  label?: string;
  value: string | number | boolean | Date;
  onChange: (value: string) => void;
  placeholder?: string;
  type?: 'string' | 'number' | 'boolean' | 'date' | 'datetime';
  error?: string;
  disabled?: boolean;
  disabledMessage?: string | React.ReactNode;
  lowerMessage?: string;
}

const Input = ({
  label,
  value,
  onChange,
  placeholder,
  error,
  disabled,
  disabledMessage,
  type,
  lowerMessage,
}: InputProps) => {
  const htmlType = useMemo(() => {
    if (type === 'number') {
      return 'number';
    }

    if (type === 'boolean') {
      return 'checkbox';
    }

    if (type === 'date') {
      return 'date';
    }

    if (type === 'datetime') {
      return 'datetime-local';
    }

    return 'text';
  }, [type]);

  return (
    <div>
      <Text type="h1">{label}</Text>
      <div className="group relative">
        <input
          value={value.toString()}
          disabled={disabled}
          onChange={(e) => onChange(e.target.value)}
          className={`h-[38px] text-sm rounded-md block w-full p-2 focus:outline-none shadow-sm ring-1 ring-inset ring-gray-300  ${
            label ? 'mt-2' : 'mt-0'
          } ${disabled ? 'cursor-not-allowed' : ''}`}
          placeholder={placeholder}
          type={htmlType || 'text'}
        />

        {lowerMessage && (
          <div className="absolute flex justify-end w-full">
            <Text type="span">{lowerMessage}</Text>
          </div>
        )}
        {disabled && disabledMessage && (
          <div className="p-2 rounded-md bg-igBoxBg2 pointer-events-none absolute -bottom-10 left-0 w-max opacity-0 transition-opacity group-hover:opacity-100">
            <Text type="span">{disabledMessage}</Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
