import LoadingIndicator from 'components/loadingIndicator';
import { useState } from 'react';

interface TableProps {
  columns: {
    key: string;
    label: string;
    width?: number;
  }[];
  rows?: {
    keys: string[];
    values: string[];
    expandedComponent?: React.ReactNode;
  }[];
  expandable?: boolean;
  loading?: boolean;
}

const Table: React.FC<TableProps> = ({
  columns,
  rows,
  expandable,
  loading,
}) => {
  const [expandedRow, setExpandedRow] = useState<string | null>(null);

  return (
    <>
      <table className="min-w-full text-left text-sm font-light">
        <TableHeader columns={columns} />
        {!loading && (
          <TableRows
            rows={rows}
            expandable={expandable}
            expandedRow={expandedRow}
            onClickExpand={setExpandedRow}
          />
        )}
      </table>

      {!loading && rows && rows.length === 0 && (
        <div className="flex w-full items-center justify-center mt-10 mb-10">
          <p className="text-gray-500">데이터가 없습니다.</p>
        </div>
      )}

      {loading && (
        <div className="flex w-full items-center justify-center mt-10 mb-10">
          <LoadingIndicator type="dot" size="lg" />
        </div>
      )}
    </>
  );
};

interface TableHeaderProps {
  columns: {
    key: string;
    label: string;
    width?: number;
  }[];
}

const TableHeader: React.FC<TableHeaderProps> = ({ columns }) => {
  const renderHeaderCell = () => {
    const generatedCells = [];

    for (const column of columns) {
      generatedCells.push(
        <th
          key={column.key}
          scope="col"
          className="px-6 py-4"
          style={{ width: column.width }}
        >
          {column.label}
        </th>,
      );
    }

    return generatedCells;
  };

  return (
    <thead className="border-b font-medium dark:border-neutral-500">
      <tr>{renderHeaderCell()}</tr>
    </thead>
  );
};

interface TableRowsProps {
  rows?: {
    keys: string[];
    values: string[];
    colors?: string[];
    expandedComponent?: React.ReactNode;
  }[];
  expandable?: boolean;
  expandedRow?: string | null;
  onClickExpand?: (id: string | null) => void;
}

const TableRows: React.FC<TableRowsProps> = ({
  rows,
  expandable,
  expandedRow,
  onClickExpand,
}) => {
  const renderRowCell = (values: string[], colors: string[]) => {
    const generatedCells = [];
    for (let index = 0; index < values.length; index++) {
      if (colors && colors[index]) {
        generatedCells.push(
          <td key={values[index]} className={`px-6 py-4 ${colors[index]}`}>
            {values[index]}
          </td>,
        );
      } else {
        generatedCells.push(
          <td key={values[index]} className="px-6 py-4">
            {values[index]}
          </td>,
        );
      }
    }

    return generatedCells;
  };

  const onClickExpandRow = (id: string) => {
    if (!expandable) {
      return;
    }

    if (expandedRow === id) {
      onClickExpand?.(null);
    } else {
      onClickExpand?.(id);
    }
  };

  const renderRows = () => {
    const generatedRows = [];

    if (rows) {
      for (const row of rows) {
        const { keys, values, colors, expandedComponent } = row;

        generatedRows.push(
          <tr
            key={values.join('-')}
            className={` hover:bg-slate-200 ${
              expandedRow === values[0] ? 'border-b-none' : 'border-b'
            }`}
            onClick={(e) => onClickExpandRow(values[0])}
          >
            {renderRowCell(values, colors || [])}
          </tr>,
        );

        generatedRows.push(
          <tr
            key={`${values.join('-')}-expanded`}
            className={`border-b ${
              expandedRow === values[0] ? '' : 'collapse'
            }`}
          >
            <td colSpan={6} className={`overflow-hidden`}>
              {expandedRow === values[0] && expandedComponent}
            </td>
          </tr>,
        );
      }
    }

    return generatedRows;
  };

  return <tbody>{renderRows()}</tbody>;
};

export default Table;
