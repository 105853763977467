import Card from 'components/card';
import PageLayout from 'components/layout/page';
import React from 'react';

import Text from 'components/text';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
  const navigate = useNavigate();

  return (
    <PageLayout>
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-4">
        <Card
          onClick={() => {
            navigate('/list');
          }}
        >
          <div>
            <Text type="h5" bold>
              List
            </Text>
            <Text type="p">저장된 데이터를 조회합니다.</Text>
          </div>
        </Card>
        {/* <Card
          onClick={() => {
            navigate('/list');
          }}
        >
          <div>
            <Text type="h5" bold>
              Upload
            </Text>
            <Text type="p">새로운 데이터를 추가합니다.</Text>
          </div>
        </Card> */}
      </div>
    </PageLayout>
  );
};

export default Home;
