import { useEffect, useMemo, useState } from 'react';

import Text from 'components/text';
import Input from 'components/input';
import Selector, { Item } from 'components/selector';
import { QueryOperator } from '.';

export interface QueryKey {
  key: string;
  label: string;
  valueType: 'string' | 'number' | 'boolean' | 'date' | 'datetime';
  operatorList: QueryOperator[];
  defaultValue?: string | number | boolean | Date;
  listItem?: {
    key: string;
    label: string;
  }[];
}

interface QueryBlockProps {
  keyList: QueryKey[];
  selectedKey: string;
  value: string | number | boolean | Date;
  selectedOperator: QueryOperator;
  onSelectKey: (key: string) => void;
  onChangeValue: (value: string | number | boolean | Date) => void;
  onChangeOperator: (operator: QueryOperator) => void;
  onDelete: () => void;
}

const QueryBlock: React.FC<QueryBlockProps> = ({
  keyList,
  selectedKey,
  value,
  selectedOperator,
  onChangeValue,
  onSelectKey,
  onChangeOperator,
  onDelete,
}) => {
  const keyItems = useMemo(() => {
    return keyList.map((key) => ({ key: key.key, label: key.label }));
  }, [keyList]);

  const selectedKeyItem = useMemo(() => {
    return keyList.find((key) => key.key === selectedKey);
  }, [keyList, selectedKey]);

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full gap-1 items-center p-2 rounded-lg bg-slate-200 shadow-s flex-col lg:flex-row">
        <div className="w-full lg:w-[140px]">
          <Selector
            items={keyItems}
            selected={
              keyItems.find((item) => item.key === selectedKey) || keyItems[0]
            }
            onSelect={(item) => {
              onSelectKey(item.key);
            }}
          />
        </div>
        <div className="w-full lg:w-[100px]">
          <Selector
            items={
              selectedKeyItem?.operatorList.map((op) => ({
                key: op,
                label: op,
              })) || []
            }
            selected={{
              key: selectedOperator,
              label: selectedOperator,
            }}
            onSelect={(operator) => {
              onChangeOperator(operator.key as QueryOperator);
            }}
          />
        </div>
        <div className="w-full lg:w-[220px]">
          {selectedKeyItem?.listItem ? (
            <Selector
              items={selectedKeyItem.listItem}
              selected={
                selectedKeyItem.listItem.find((item) => item.key === value) || {
                  key: '',
                  label: '',
                }
              }
              onSelect={(item) => {
                onChangeValue(item.key);
              }}
            />
          ) : (
            <Input
              value={value}
              onChange={onChangeValue}
              type={selectedKeyItem?.valueType || 'string'}
            />
          )}
        </div>
      </div>
      <div className="flex w-full justify-end">
        <div
          className="select-none cursor-pointer text-slate-600  pr-1"
          onClick={onDelete}
        >
          <Text type="span" size="xs">
            삭제
          </Text>
        </div>
      </div>
    </div>
  );
};

export default QueryBlock;
