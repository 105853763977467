import { query, queryPatient } from 'api/query';
import ECGExpanded from 'components/expanded/ecg';
import ImageExpanded from 'components/expanded/image';
import PageLayout from 'components/layout/page';
import LoadingIndicator from 'components/loadingIndicator';
import Pagenation from 'components/pagenation';
import Query, { QueryFilter } from 'components/query';
import Table from 'components/table';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';

function List() {
  const [queryFilters, setQueryFilters] = useState<QueryFilter[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageLimit, setPageLimit] = useState<number>(10);
  const [totalSearchedCount, setTotalSearchedCount] = useState<number>(0);

  const isMobile = useMediaQuery({
    query: '(min-width:0px) and (max-width:640px)',
  });

  const tableColumns = useMemo(() => {
    const generateTableColumns = () => {
      const columns = [
        {
          key: 'id',
          label: '#',
        },
        {
          key: 'name',
          label: '이름',
        },
        {
          key: 'data_type',
          label: '데이터 타입',
        },
      ];

      if (isMobile) {
        return columns;
      }

      return columns.concat([
        {
          key: 'device',
          label: '측정장비',
        },
        {
          key: 'surgery',
          label: '수술여부',
        },
        {
          key: 'created_at',
          label: '생성일시',
        },
      ]);
    };

    return generateTableColumns();
  }, [isMobile]);

  const queryData = useMutation(async () => {
    const response = await query(queryFilters, {
      page,
      limit: pageLimit,
    });

    if (response.status === 200) {
      return response.data;
    }
  });

  const patientData = useMutation(async () => {
    const response = await queryPatient();

    if (response.status === 200 && response.data) {
      const parsedData: { [key: string]: any } = {};

      response.data.result.forEach((row: any) => {
        const patientId: string = row.id.toLowerCase();
        parsedData[patientId] = row;
      });

      return parsedData;
    }
  });

  const tableRows = useMemo(() => {
    const generateTableRows = () => {
      const { data } = queryData;

      if (!data) {
        return [];
      }

      const rows = [];

      const { result, count } = data;

      for (const item of result) {
        const type = item.data_type;

        let surgery =
          patientData.data && patientData.data[item.name]
            ? patientData.data[item.name]
            : 'N/A';

        if (surgery != 'N/A') {
          const date = moment(surgery.surgeryDate, 'YYYY.MM.DD');
          const dateCreatedAt = moment(item.data_created_date);

          if (dateCreatedAt.isAfter(date)) {
            surgery = 'O';
          } else {
            surgery = 'X';
          }
        }

        if (patientData.isLoading) {
          surgery = <LoadingIndicator size="sm" type="dot" />;
        }

        if (isMobile) {
          rows.push({
            keys: [`id${item.id}`, `name${item.id}`, `data_type${item.id}`],
            values: [item.id, item.name, item.data_type],
            colors: [item.abnormal ? 'text-red' : null, null, null, null, null],
            expandedComponent:
              type === 'ECG' ? (
                <ECGExpanded
                  id={item.id}
                  rawData={{
                    ...item,
                    patient: patientData.data
                      ? patientData.data[item.name]
                      : null,
                  }}
                  onReloadRequired={queryData.mutate}
                />
              ) : (
                <ImageExpanded
                  id={item.id}
                  rawData={item}
                  onReloadRequired={queryData.mutate}
                />
              ),
          });
        } else {
          rows.push({
            keys: [
              `id${item.id}`,
              `name${item.id}`,
              `data_type${item.id}`,
              `surgery${item.id}`,
              `created_at${item.id}`,
            ],
            values: [
              item.id,
              item.name,
              item.data_type,
              item.device,
              surgery,
              item.data_created_date,
            ],
            colors: [
              item.abnormal ? 'text-red-400' : null,
              item.abnormal ? 'text-red-400' : null,
              item.abnormal ? 'text-red-400' : null,
              item.abnormal ? 'text-red-400' : null,
              item.abnormal ? 'text-red-400' : null,
              item.abnormal ? 'text-red-400' : null,
            ],
            expandedComponent:
              type === 'ECG' ? (
                <ECGExpanded
                  id={item.id}
                  rawData={{
                    ...item,
                    patient: patientData.data
                      ? patientData.data[item.name]
                      : null,
                  }}
                  onReloadRequired={queryData.mutate}
                />
              ) : (
                <ImageExpanded
                  id={item.id}
                  rawData={{
                    ...item,
                  }}
                  onReloadRequired={queryData.mutate}
                />
              ),
          });
        }
      }

      return rows;
    };

    return generateTableRows();
  }, [queryData.data, isMobile, patientData.data]);

  const onClickSearch = () => {
    setPage(1);
    queryData.mutate();
  };

  useEffect(() => {
    if (queryData.data) {
      setTotalSearchedCount(queryData.data.count);
    }
  }, [queryData.data]);

  useEffect(() => {
    queryData.mutate();
  }, [page, pageLimit]);

  useEffect(() => {
    patientData.mutate();
  }, []);

  return (
    <PageLayout>
      <div className="flex w-full h-full flex-col lg:flex-row">
        <div className="sm:min-w-[520px]">
          <Query
            queryFilters={queryFilters}
            setQueryFilters={setQueryFilters}
            onClickSearch={onClickSearch}
          />
        </div>
        <div className="flex-1 rounded-lg p-4 mt-4 ml-0 lg:mt-0 sm:ml-4">
          <Table
            columns={tableColumns}
            rows={tableRows}
            loading={queryData.isLoading}
            expandable
          />
          {!queryData.isLoading && (
            <Pagenation
              total={totalSearchedCount}
              limit={pageLimit}
              currentPage={page}
              setCurrentPage={setPage}
              setPageLimit={setPageLimit}
            />
          )}
        </div>
      </div>
    </PageLayout>
  );
}

export default List;
