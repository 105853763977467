interface TextProps {
  children: React.ReactNode;
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
  bold?: boolean;
}

const Text: React.FC<TextProps> = ({ children, type = 'p', bold, size }) => {
  const Tag = type;
  const generateFontSize = () => {
    if (size) {
      return `text-${size}`;
    }

    switch (type) {
      case 'h1':
        return 'text-4xl';
      case 'h2':
        return 'text-3xl';
      case 'h3':
        return 'text-2xl';
      case 'h4':
        return 'text-xl';
      case 'h5':
        return 'text-lg';
      case 'h6':
        return 'text-base';
      case 'p':
        return 'text-base';
      case 'span':
        return 'text-sm';
    }
  };
  return (
    <Tag className={`${bold ? 'font-bold' : ''} ${generateFontSize()}`}>
      {children}
    </Tag>
  );
};

export default Text;
